"use client";

import { useEffect, useRef } from "react";
import { useSearchParams } from "next/navigation";

/**
 * @component
 *
 */
export function Utm() {
  const search = useRef(useSearchParams());

  useEffect(() => {
    // Not supported
    if (!search) return;
    if (typeof sessionStorage === "undefined") return;

    // Query params
    const queryS = [...search.current.entries()];
    if (queryS?.length === 0) return;
    const utmParams = queryS.filter((e) => e[0].startsWith("utm_"));

    // Save entry query
    window.sessionStorage.setItem("utm", JSON.stringify(utmParams));
  }, []);

  return null;
}

"use client";

import React, { type ReactNode } from "react";
import { HubspotProvider } from "next-hubspot";

export default function HubspotProviderWrapper({
  children
}: Readonly<{
  children: ReactNode;
}>) {
  return <HubspotProvider>{children}</HubspotProvider>;
}
